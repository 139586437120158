import Head from 'next/head';

const GoogleAuthScript = () => {
  return (
    <Head>
      <script src="https://accounts.google.com/gsi/client" async defer />
    </Head>
  );
};

export default GoogleAuthScript;
